import Base from "@/scripts/extends/Base";

/**
 * Every class based on a DOM element should extend from this file. Rules:
 * - Never write querySelectors that select an element outside the root element
 *   (this.el) of this file.
 * - The name of this file and the component class must be the same as the class
 *   selected and assigned to this.el.
 * - The DOM element selected must always be the root of the component's markup
 *   defined using BEM.
 */
export default class Element extends Base {
	init(el, ...params) {
		// Set the root element of the component class
		this.el = el;

		// Execute mounted when element is defined and pass parameters
		if (this.mounted) {
			this.mounted(...params);
		}
	}
}
