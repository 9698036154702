import {DomManager, Store} from "@/scripts/core";
import components from "@/scripts/components";
import modules from "@/scripts/modules";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

import "@/styles/main.css";

/**
 * Set up the initial values and keys for the store. This should be done first
 * thing before mounting and creating the components.
 */
Store.setState({});

// EXTEND:Sprig + Vanilla JS
function init() {
	DomManager.setComponents(components);
	DomManager.setModules(modules);
}

DomManager.define({
	/**
	 * Triggers first and immediately, does not wait for a state. Only
	 * initialize DOM based classes that do not require calculations with
	 * dimensions, because the dimensions of certain elements might not be set
	 * immediately.
	 */
	created() {},

	/**
	 * Triggers on 'interactive' state, if 'interactive' never fires, it is
	 * triggered on 'complete' state. Dimensions are known and set
	 */
	mounted() {
		// Register plugins
		gsap.registerPlugin(ScrollTrigger);

		// Update favicon when browsertab is inactive
		window.addEventListener("visibilitychange", () => {
			const pathArray = window.location.pathname.split('/');
			let url = window.location.protocol + "//" + window.location.host;
			if (pathArray) url = url + '/' + pathArray[1];

			if (document.hidden) {
				document.getElementById("favicon-large").setAttribute("href", `${url}/assets/favicon/sleep/favicon-32x32.png`);
				document.getElementById("favicon-small").setAttribute("href", `${url}/assets/favicon/sleep/favicon-16x16.png`);
				document.getElementById("favicon-ico").setAttribute("href", `${url}/assets/favicon/sleep/favicon.ico`);
			} else {
				document.getElementById("favicon-large").setAttribute("href", `${url}/assets/favicon/favicon-32x32.png`);
				document.getElementById("favicon-small").setAttribute("href", `${url}/assets/favicon/favicon-16x16.png`);
				document.getElementById("favicon-ico").setAttribute("href", `${url}/assets/favicon/favicon.ico`);
			}
		});

		const urlParams = new URLSearchParams(window.location.search);
		const challengeId = urlParams.get("challenge");

		if (challengeId) {
			this.dialog = document.getElementById('challenge-' + challengeId);
			document.body.classList.add("has-scroll-disabled", "has-scroll-disabled--mobile");
			this.dialog.showModal();
		}

		init();
	},

	// Triggers last and on 'complete' state
	completed() {
		const anchor = getAnchor();
		if (anchor) {
			const element = document.getElementById(anchor);
			delay(500).then(() => element.scrollIntoView());
		}
	},
});

function getAnchor() {
	var currentUrl = document.URL,
		urlParts   = currentUrl.split('#');

	return (urlParts.length > 1) ? urlParts[1] : null;
}

function delay(time) {
	return new Promise(resolve => setTimeout(resolve, time));
}
