import { Element } from "@/scripts/extends";
import { atcb_action } from "add-to-calendar-button";

export default class AddToCalendar extends Element {
	mounted() {
		this.config = {
			name: "Registration Hack The Future - Among The Stars",
			startDate: "2024-09-17",
			endDate: "2024-09-17",
			options: ["Apple", "Google"],
			timeZone: "Europe/Brussels",
			lightMode: "dark"
		};
	}

	events() {
		this.el.addEventListener("click", () => atcb_action(this.config, this.el));
	}
}
