import { Element } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

export default class Dialog extends Element {
	mounted() {
		this.body = document.body;
		this.target = this.el.dataset.target;
		this.dialog = document.getElementById(this.el.dataset.target);
		this.closeButton = this.dialog.querySelector(".dialog-close");

		this.video = this.dialog.querySelector(".js-video");
		this.hasVideo = !!this.video;
	}

	events() {
		this.el.addEventListener("click", (e) => {
			e.preventDefault();
			this.body.classList.add("has-scroll-disabled", "has-scroll-disabled--mobile");
			this.dialog.showModal();
			if (this.hasVideo) EventEmitter.emit(EVENTS.MODAL_OPEN, this.video);
		});

		this.closeButton.addEventListener("click", () => {
			this.body.classList.remove("has-scroll-disabled", "has-scroll-disabled--mobile");
			this.dialog.close();
			if (this.hasVideo) EventEmitter.emit(EVENTS.MODAL_CLOSE, this.video);
		});

		document.addEventListener("keyup", (e) => {
			if (e.key === "Escape") {
				this.body.classList.remove("has-scroll-disabled", "has-scroll-disabled--mobile");
				if (this.hasVideo) EventEmitter.emit(EVENTS.MODAL_CLOSE, this.video);
			}
		});
	}
}
