import Emitter from "eventemitter3";

class EventEmitter extends Emitter {
	hasEvent(targetEventName) {
		const eventNames = this.eventNames();

		return !!eventNames.find((eventName) => eventName === targetEventName);
	}
}

const eventEmitter = new EventEmitter();

export default eventEmitter;
