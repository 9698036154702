import { Element } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

export default class Video extends Element {
	mounted() {
		this.frame = this.el.querySelector(".c-video__frame");
		this.videoEl = this.el.querySelector(".c-video--embed");
		this.providerName = this.videoEl.dataset.player.toLowerCase();

		this.playerOptions = {
			youtube: {
				pause: {
					event: "command",
					func: "pauseVideo",
				},
				play: {
					event: "command",
					func: "playVideo",
				},
			},
			vimeo: {
				pause: {
					method: "pause",
				},
				play: {
					method: "play",
				},
			},
		};
	}

	events() {
		EventEmitter.on(EVENTS.MODAL_CLOSE, (el) => this.handleModalClose(el));
		EventEmitter.on(EVENTS.MODAL_OPEN, (el) => this.handleModalOpen(el));

		if (this.videoEl && this.frame) {
			this.frame.addEventListener("load", () => {
				this.listenToEmbed();
				this.initEmbedReady();
			});
		}
	}

	handleModalClose(el) {
		if (this.el === el) {
			this.postMessageToPlayer('pause');
		}
	}

	handleModalOpen(el) {
		if (this.el === el) {
			this.postMessageToPlayer('play');
		}
	}

	postMessageToPlayer(command) {
		if (command == null) return;

		if (this.playerOptions[this.providerName]) {
			const postCommand = this.playerOptions[this.providerName][command];
			this.frame.contentWindow.postMessage(JSON.stringify(postCommand), "*");
		}
	}

	initEmbedReady() {
		this.el.classList.remove("is-loading");

		if (this.el.classList.contains("js-autoplay")) {
			this.autoplayEmbed();
		}
	}

	listenToEmbed() {
		this.postMessageToPlayer({
			event: "listening",
			id: "1",
			channel: "widget",
		});
	}

	autoplayEmbed() {
		this.src = this.frame.getAttribute("src");
		this.frame.setAttribute("src", `${this.src}&autoplay=1`);
	}
}
