import { Base } from "@/scripts/extends";
import EventEmitter from "@/scripts/core/EventEmitter";
import { STORE } from "@/scripts/constants";
import { get, isEqual } from "lodash-es";

class Store extends Base {
	init() {
		this.isStateReady = false;
		this.state = {};
	}

	/**
	 * Initialize the state, this can only be done once and should only be done
	 * when the JavaScript is executed before the components and other modules
	 * are initialized.
	 */
	setState(state) {
		if (this.isStateReady) {
			return;
		}

		this.state = state;
		this.isStateReady = true;
	}

	/**
	 * Update a property in the state, once updated and the value is changed, an
	 * event is triggered. All items that are listening to the event are
	 * triggered as well.
	 */
	update(key, newValue, params = {}) {
		const hasKey = this.get(key) !== undefined;

		if (!hasKey) {
			return;
		}

		const value = this.state[key];

		if (isEqual(value, newValue)) {
			return;
		}

		this.state[key] = newValue;

		EventEmitter.emit(`${STORE.UPDATE_EVENTS_PREFIX}-${key}`, newValue, params);
	}

	/**
	 * Listen a property within the store by passing in the key presented in the
	 * state object, when a property updates, the listeners are triggered.
	 */
	listen(key, callback) {
		EventEmitter.on(`${STORE.UPDATE_EVENTS_PREFIX}-${key}`, callback);
	}

	/**
	 * Stop listening to a property and remove the trigger when a property is
	 * updated or changed within the state object.
	 */
	stopListening(key, callback) {
		EventEmitter.removeListener(`${STORE.UPDATE_EVENTS_PREFIX}-${key}`, callback);
	}

	// Get a key from the state object, if it does not exist, return null
	get(key) {
		return get(this.state, key, undefined);
	}
}

const store = new Store();

export default store;
