import { Element } from "@/scripts/extends";

export default class Toggle extends Element {
	mounted() {
		this.panels = this.el.querySelectorAll(".c-toggle-panel");
		this.buttons = this.el.querySelectorAll(".c-toggle-button");
		this.buttonActiveClass = "c-toggle-button--active";
		this.panelActiveClass = "c-toggle-panel--active";

		this.setPanelHeight();
	}

	events() {
		this.buttons.forEach((item) => {
			item.addEventListener("click", (e) => {
				if (!item.classList.contains(this.buttonActiveClass)) this.toggle(e)
			});
		});

		this.resizeObserver = new ResizeObserver(() => this.onResize());
		this.resizeObserver.observe(this.el);
	}

	toggle() {
		this.buttons.forEach((item) => {
			item.classList.toggle(this.buttonActiveClass);
		});

		this.panels.forEach((item) => {
			item.classList.toggle(this.panelActiveClass);
		});

		this.setPanelHeight();
	}

	getHeight(item) {
		if (item !== null) {
			const { height } = item.querySelector(".c-toggle-panel__content").getBoundingClientRect();

			return height;
		}

		return 0;
	}

	setPanelHeight() {
		this.panels.forEach((item) => {
			item.classList.contains(this.panelActiveClass) ? this.setHeight(item) : this.unsetHeight(item);
		});
	}

	setHeight(item) {
		const height = this.getHeight(item);

		item.style.setProperty("--c-toggle-panel-height", `${height}px`);
	}

	unsetHeight(item) {
		item.style.setProperty("--c-toggle-panel-height", 0);
	}

	onResize() {
		this.setPanelHeight();
	}
}
