/**
 * Rules:
 * - Make sure that the corresponding code belongs to the right functions.
 *   "init()" should contain all properties. "events()" should contain all
 *   EventListeners, ...
 */
export default class Base {
	constructor(...params) {
		// Execute init to set up properties and pass params
		if (this.init) {
			this.init(...params);
		}

		// Execute events after all selectors are defined
		if (this.events) {
			this.events();
		}
	}
}
