import {Element} from "@/scripts/extends";

export default class Ring extends Element {
	mounted() {
		this.elementX = 0;
		this.elementY = 0;
		this.isOutside = 0;
		this.elementHeight = 0;
		this.elementWidth = 0;
		this.MAX_ROTATION = 30;
		this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		this.useMouseInElement(this.el);
	}

	events() {
		// Attach the updateCardTransform to the mousemove event
		document.addEventListener("mousemove", () => this.updateCardTransform());
	}

	updateCardTransform() {
		if (window.innerWidth > 768 && !this.isSafari) {
			const { rX, rY } = this.calculateRotation(
				this.elementX,
				this.elementY,
				this.elementHeight,
				this.elementWidth,
				this.MAX_ROTATION
			);

			this.applyTransform(this.el, rX, rY, this.elementWidth);
		}
	};

	// Function to calculate the mouse position relative to the element
	useMouseInElement(element) {
		const updateMousePosition = (event) => {
			const rect = element.getBoundingClientRect();
			this.elementHeight = rect.height;
			this.elementWidth = rect.width;

			this.elementX = event.clientX - rect.left;
			this.elementY = event.clientY - rect.top;

			this.isOutside = this.elementX < 0 || this.elementX > rect.width || this.elementY < 0 || this.elementY > rect.height;
		};

		// Add event listeners to track mouse movement inside and outside the element
		document.addEventListener("mousemove", updateMousePosition);
	}

	// Function to calculate rotation values
	calculateRotation(elementX, elementY, elementHeight, elementWidth, maxRotation = 30) {
		const clamp = (value, min, max) => Math.max(min, Math.min(max, value));

		let rX = (maxRotation / 2 - (elementY / elementHeight) * maxRotation).toFixed(2);
		let rY = ((elementX / elementWidth) * maxRotation - maxRotation / 2).toFixed(2);

		// Clamp the rotation values to ensure they stay within the range
		rX = clamp(rX, -maxRotation, maxRotation);
		rY = clamp(rY, -maxRotation, maxRotation);

		return { rX, rY };
	}

	// Function to apply the transform style to the target element
	applyTransform(target, rX, rY, elementWidth) {
		target.style.transform = `perspective(${elementWidth}px) rotateX(${rX}deg) rotateY(${rY}deg)`;
	}
}


