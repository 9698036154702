/**
 * In the /scripts/singletons/DomManager file, when using the setComponents
 * method, all components placed in export default are looped and initialized
 * automatically.
 */
import AddToCalendar from "@/scripts/components/AddToCalendar";
import Collapse from "@/scripts/components/Collapse";
import Dialog from "@/scripts/components/Dialog";
import Navigation from "@/scripts/components/Navigation";
import Ring from "@/scripts/components/Ring";
import Story from "@/scripts/components/Story";
import Toggle from "@/scripts/components/Toggle";
import Video from "@/scripts/components/Video";

/**
 * If you want to pass separate parameters or want to initialize a component
 * manually, you have to:
 * - Export it by itself =>
 *   export { default as Button } from "@/scripts/components/Button";
 * - Import it in the main.js file =>
 *   import { Button } from "@/scripts/components";
 * - Use the DomManager to register it:
 *   DomManager.registerComponent(".js-button", Button);
 */
// export { default as Button } from "@/scripts/components/Button";

export default {
	AddToCalendar,
	Collapse,
	Dialog,
	Navigation,
	Ring,
	Story,
	Toggle,
	Video
};
