import { Element } from "@/scripts/extends";
import gsap from "gsap";

export default class Story extends Element {
	mounted() {
		const blocks = gsap.utils.toArray(".c-story__block");

		setTimeout(() => {
			this.initGsapAnimation(blocks);
		}, 100);
	}

	initGsapAnimation(blocks){
		blocks.forEach((block) => {
			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: block,
					pin: true,
					pinSpacing: false,
					scrub: true
				}
			});

			tl.to(block, {
				autoAlpha: 1
			}).to(
				block,
				{
					autoAlpha: 0
				},
				0
			);
		});
	}
}
