import { Element } from "@/scripts/extends";

export default class Navigation extends Element {
	mounted() {
		this.isOpen = false;
		this.body = document.body;
		this.trigger = this.el.querySelector(".c-hamburger");
		this.navItems = this.el.querySelectorAll(".c-navigation__item");

		// Hamburger
		this.openClass = "is-cross";
		this.label = this.trigger.dataset.label;
		this.openLabel = this.trigger.dataset.labelOpen;
		this.closeLabel = this.trigger.dataset.labelClose;
	}

	events() {
		if (!this.trigger) {
			return;
		}

		// Toogle trigger (hamburger)
		this.trigger.addEventListener("click", () => this.toggle());

		// Hide nav when anchor link is clicked
		this.navItems.forEach((navItem) => navItem.addEventListener("click", () => this.disable()));
	}

	toggle() {
		if (this.isOpen) {
			this.disable();
		} else {
			this.enable();
		}
	}

	enable() {
		this.el.classList.add("is-open");
		this.body.classList.add("has-scroll-disabled", "has-scroll-disabled--mobile");
		this.isOpen = true;
		this.openHamburger();
	}

	disable() {
		this.el.classList.remove("is-open");
		this.body.classList.remove("has-scroll-disabled", "has-scroll-disabled--mobile");
		this.isOpen = false;
		this.closeHamburger();
	}

	closeHamburger() {
		this.trigger.classList.remove(this.openClass);
		this.trigger.setAttribute("aria-label", this.openLabel);
		this.trigger.setAttribute("aria-expanded", "true");

		this.label = this.openLabel;
	}

	openHamburger() {
		this.trigger.classList.add(this.openClass);
		this.trigger.setAttribute("aria-label", this.closeLabel);
		this.trigger.setAttribute("aria-expanded", "false");

		this.label = this.closeLabel;
	}
}
